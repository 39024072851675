import { createSlice } from "@reduxjs/toolkit";
import { getErrorMsg, makeRequest } from "utils";
import { BASE_API_URL } from "../db";
import { showAlertAction } from "./alert";

const slice = createSlice({
  name: "lists",
  initialState: {
    states: null,
    zones: null,
    areas: null,
    branches: null,
    cos: null,
    clients: null,
    loading: false,
  },
  reducers: {
    getClientsSuccess: (state, action) => {
      state.clients = action.payload;
    },
    getCosSuccess: (state, action) => {
      state.cos = action.payload;
    },
    getBranchesSuccess: (state, action) => {
      state.branches = action.payload;
    },
    getAreasSuccess: (state, action) => {
      state.areas = action.payload;
    },
    getZonesSuccess: (state, action) => {
      state.zones = action.payload;
    },
    getStatesSuccess: (state, action) => {
      state.states = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export default slice.reducer;

const isPermittedOrError = (permissionLevel, permissionThreshold) => {
  if (permissionLevel < permissionThreshold) {
    throw new Error("You do not have enough permission to view this resourse");
  }
};

const {
  getAreasSuccess,
  getCosSuccess,
  getBranchesSuccess,
  getClientsSuccess,
  getStatesSuccess,
  getZonesSuccess,
  setLoading,
} = slice.actions;

export const getAllClientsAction =
  ({ coId, permissionLevel }) =>
  async (dispatch) => {
    // Get All clients with this CO as their registerer
    try {
      isPermittedOrError(permissionLevel, 1);
      dispatch(setLoading(true));
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/clients/?co_id=${coId}`,
        "GET"
      );

      dispatch(getClientsSuccess(res));
      dispatch(setLoading(false));
      return true;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getAllCoAction =
  ({ orgIdField = "", orgId = "", permissionLevel }) =>
  async (dispatch) => {
    // Get All clients with this CO as their registerer
    try {
      isPermittedOrError(permissionLevel, 2);
      dispatch(setLoading(true));
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/users/?${orgIdField}=${orgId}&position=CO`,
        "GET"
      );

      dispatch(getCosSuccess(res));
      dispatch(setLoading(false));
      return true;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getAllBranchesAction =
  ({ orgIdField = "", orgId = "", permissionLevel }) =>
  async (dispatch) => {
    // Get All clients with this CO as their registerer
    try {
      isPermittedOrError(permissionLevel, 3);
      dispatch(setLoading(true));
      const res = await makeRequest(
        `${BASE_API_URL}/org/branches/?${orgIdField}=${orgId}`,
        "GET"
      );

      dispatch(getBranchesSuccess(res));
      dispatch(setLoading(false));
      return true;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getAllAreaAction =
  ({ orgIdField = "", orgId = "", permissionLevel }) =>
  async (dispatch) => {
    // Get All clients with this CO as their registerer
    try {
      isPermittedOrError(permissionLevel, 4);
      dispatch(setLoading(true));
      const res = await makeRequest(
        `${BASE_API_URL}/org/areas/?${orgIdField}=${orgId}`,
        "GET"
      );

      dispatch(getAreasSuccess(res));
      dispatch(setLoading(false));
      return true;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getAllZonesAction =
  ({ orgIdField = "", orgId = "", permissionLevel }) =>
  async (dispatch) => {
    // Get All clients with this CO as their registerer
    try {
      isPermittedOrError(permissionLevel, 5);
      dispatch(setLoading(true));
      const res = await makeRequest(
        `${BASE_API_URL}/org/zones/?${orgIdField}=${orgId}`,
        "GET"
      );

      dispatch(getZonesSuccess(res));
      dispatch(setLoading(false));
      return true;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getAllStateAction =
  ({ directorId, permissionLevel }) =>
  async (dispatch) => {
    try {
      isPermittedOrError(permissionLevel, 6);
      const data = { state_id: directorId };
      dispatch(setLoading(true));
      const res = await makeRequest(`${BASE_API_URL}/org/states/`, "GET");

      await dispatch(getStatesSuccess(res));
      dispatch(setLoading(false));
      return true;
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const assignListClientsToCoAction =
  (data) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/clients/reassign/`,
        "POST",
        data
      );

      await dispatch(
        showAlertAction({
          message: "Clients has been sucessfully re-assigned!",
          level: "success",
          isVisible: true,
        })
      );
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message || "An error occured, please try again",
          level: "danger",
          isVisible: true,
        })
      );
      dispatch(setLoading(false));
    }
  };
