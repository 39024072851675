import React, { useCallback, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { assignListClientsToCoAction } from "store/lists";
import { ModifiedAntTable } from "components/ModifiedAntTable";
import { useListTitle } from "hooks/useListTitle";
import { useDashboardGuarantorList } from "hooks/useDashboardGuarantorList";
import { showAlertAction } from "store/alert";
import { deleteGuarantorAction } from "store/clients";
import { Link } from "react-router-dom";

const DashboardGuarantorList = () => {
  const {
    id,
    handleListRefreshTrigger,
    cos,
    loading,
    guarantorList,
    filterName,
    setFilterName,
    totalPageNumber,
    page,
    setPage,
  } = useDashboardGuarantorList();
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [selectedRowKeysState, setSelectedRowKeysState] = useState([]);
  const [selectAction, setSelectAction] = useState();
  const { fullListName } = useListTitle("Guarantors");
  const searchQuery = window.location.search;

  const handleDeleteGuarantor = async (id, guaranteed_loan) => {
    if (guaranteed_loan) {
      dispatch(
        showAlertAction({
          message: "You cannot delete a guarantor that has a guaranteed loan.",
          level: "danger",
          isVisible: true,
        })
      );
      return;
    }
    if (window.confirm("Are you sure you want to delete this guarantor?.")) {
      await dispatch(deleteGuarantorAction(id));
      handleListRefreshTrigger();
    }
  };

  const handleReAssign = async (CoId) => {
    const ids = selectedRowKeysState?.join(",");
    await dispatch(
      assignListClientsToCoAction({
        clientsToAssign: ids,
        newCoId: CoId,
        oldCoId: id,
      })
    );

    handleListRefreshTrigger();
  };

  const getClientColumns = useCallback(() => {
    const columns = [
      {
        title: "S/N",
        dataIndex: "id",
        render: (text, record, index) =>
          ((Number(page) || 1) - 1) * 50 + (index + 1),
        summary: ["-", "Summations"],
      },
      {
        title: "Name",
        dataIndex: "name",
        render: (text, record) => `${record?.surname} ${record?.firstname}`,
      },
      {
        title: "Credit Officer",
        dataIndex: "co_fullname",
      },
      {
        title: "Number of Loans Guaranteed",
        dataIndex: "guaranteed_loan",
      },
      {
        title: "Loans Guaranteed",
        render: (text, record) => {
          // Same as current address, but for loans
          const baseLoansAddress =
            `${window.location.pathname}${window.location.search}`.replace(
              "guarantors",
              "loans"
            );
          return (
            <Link
              to={
                searchQuery
                  ? `${baseLoansAddress}&guarantor_id=${record.id}&guarantor_name=${record.surname}`
                  : `${baseLoansAddress}?guarantor_id=${record.id}&guarantor_name=${record.surname}`
              }
            >
              <i className="fa fa-link" aria-hidden="true"></i> View
            </Link>
          );
        },
      },
    ];

    if (["DR", "SM", "ZM"].includes(user?.position)) {
      columns.push({
        title: "",
        dataIndex: "id",
        render: (text, record) => (
          <span
            role="button"
            onClick={() =>
              handleDeleteGuarantor(record?.id, record.guaranteed_loan)
            }
          >
            <i className="fas fa-trash"></i>
          </span>
        ),
        summary: "-",
      });
    }

    return columns;
  }, [user]);

  return (
    <Container fluid>
      <h3 className="page-title mb-4">{fullListName}</h3>

      <Input.Group compact className="mb-3 filter-panel">
        <Input
          placeholder="Filter by name"
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
      </Input.Group>

      {!!selectedRowKeysState?.length && user?.position !== "CO" && (
        <Row>
          <Col md="4">
            <Select
              placeholder="Choose action"
              className="default-ant-select mb-3"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => setSelectAction(value)}
            >
              <Select.Option value={"newCo"} key={"newCo"}>
                Assign to new CO
              </Select.Option>
            </Select>
          </Col>

          {selectAction === "newCo" && (
            <Col md="4" className="align-items-center">
              <Select
                placeholder="Choose action"
                className="default-ant-select mb-3"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value) => handleReAssign(value)}
                value={null}
                showSearch
              >
                {cos?.map((val, index) => (
                  <Select.Option value={val?.id} key={val?.id} id={val?.id}>
                    {val?.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}
        </Row>
      )}

      {loading && (
        <div className="d-flex w-100 justify-content-center align-items-center my-2">
          <i class="fas fa-spinner fa-spin "></i>{" "}
          <span className="ml-2">Loading...</span>
        </div>
      )}

      <ModifiedAntTable
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedRowKeysState,
          onChange: (selectedRowKeys) =>
            setSelectedRowKeysState(selectedRowKeys),
        }}
        rowKey="id"
        scroll={{ x: "max-content" }}
        columns={getClientColumns()}
        dataSource={guarantorList}
        bordered
        showSummary={false}
        pagination={{
          defaultCurrent: page,
          current: page,
          defaultPageSize: 50,
          hideOnSinglePage: true,
          onChange: (current) => setPage(current),
          total: totalPageNumber,
        }}
      />
    </Container>
  );
};

export { DashboardGuarantorList };
